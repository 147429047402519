import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["correctChoiceInput"];

  connect () {
    this.correctChoiceInputTargets.forEach((input) => {
      input.addEventListener("change", (e) => this._onCorrectChoiceInputChange(e));
    });
  }

  _onCorrectChoiceInputChange = (e) => {
    this.correctChoiceInputTargets.forEach((input) => {
      input.checked = e.target === input;
    });
  };
}
