import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "booleanChoice",
    "choiceId",
    "deleteQuestionButton",
    "likertScale",
    "markChoiceForDestroy",
    "markQuestionForDestroy",
    "multipleChoice",
    "question",
    "questionType"];

  connect () {
    if (this.questionTypeTarget.value === "boolean") {
      this._showAndEnableTarget(this.booleanChoiceTarget);
    } else if (this.questionTypeTarget.value === "multiple_choice") {
      this._showAndEnableTarget(this.multipleChoiceTarget);
    } else if (this.questionTypeTarget.value === "likert_scale") {
      this._showAndEnableTarget(this.likertScaleTarget);
    }

    this.deleteQuestionButtonTarget.addEventListener("click", () => this._deleteQuestion());
    this.questionTypeTarget.addEventListener("change", (e) => this._onQuestionTypeChange(e.target.value));
  }

  _onQuestionTypeChange = (questionType) => {
    if (questionType === "boolean") {
      this._hideAndDisableTarget(this.multipleChoiceTarget);
      this._hideAndDisableTarget(this.likertScaleTarget);
      this._showAndEnableTarget(this.booleanChoiceTarget);
    } else if (questionType === "multiple_choice") {
      this._hideAndDisableTarget(this.booleanChoiceTarget);
      this._hideAndDisableTarget(this.likertScaleTarget);
      this._showAndEnableTarget(this.multipleChoiceTarget);
    } else if (questionType === "likert_scale") {
      this._hideAndDisableTarget(this.booleanChoiceTarget);
      this._hideAndDisableTarget(this.multipleChoiceTarget);
      this._showAndEnableTarget(this.likertScaleTarget);
    } else {
      this._hideAndDisableTarget(this.likertScaleTarget);
      this._hideAndDisableTarget(this.booleanChoiceTarget);
      this._hideAndDisableTarget(this.multipleChoiceTarget);
    }
  };

  _targetChoicesToDestroy = (target) => {
    return this.markChoiceForDestroyTargets.filter((choiceTarget) => target.contains(choiceTarget));
  };

  _targetChoicesWithIds = (target) => {
    return this.choiceIdTargets.filter((choiceTarget) => target.contains(choiceTarget));
  };

  _markForDestruction = (target) => {
    this._targetChoicesToDestroy(target).forEach((input) => {
      input.checked = true;
    });
  };

  _unMarkForDestruction = (target) => {
    this._targetChoicesToDestroy(target).forEach((input) => {
      input.checked = false;
    });
  };

  _hideAndDisableTarget = (target) => {
    target.classList.add("hidden");

    if (this._targetChoicesWithIds(target).length !== 0) {
      // it has persisted answers
      this._markForDestruction(target);
    } else {
      // it has no persisted answers
      target.querySelectorAll("input").forEach(input => {
        input.disabled = true;
      });
    }
  };

  _showAndEnableTarget = (target) => {
    target.classList.remove("hidden");
    this._unMarkForDestruction(target);

    target.querySelectorAll("input").forEach(input => {
      input.disabled = input.dataset.hasAnswers === "true";
    });
  };

  _deleteQuestion = () => {
    if (this.hasMarkQuestionForDestroyTarget) {
      this.markQuestionForDestroyTarget.checked = true;
      this.questionTarget.classList.add("hidden");
    } else {
      this.questionTarget.remove();
    }
  };
}
