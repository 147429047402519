import ApplicationController from "controllers/application_controller";

// Triggers subscribing to a presentation (e.g. on button click)
export default class extends ApplicationController {
  connect () {
    const id = this.element.dataset.presentation;
    const secondScreen = this.element.dataset.secondScreen === "true";
    const action = secondScreen ? "followPresentationChannel" : "leadPresentationChannel";

    // Note: requires window.app.joinPresentationChannel to be set (from channels/index.js)
    if (window.app && window.app[action]) {
      window.app[action](id);
    } else {
      console.warn(`Could not find window.app['${action}'] -- are channels imported correctly?`);
    }
  }
}
