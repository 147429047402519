import { Controller } from "@hotwired/stimulus";
import Cookies from "js-cookie";

// Note optional existing language, and in case we append anything else to the URL string later we're keeping that too
const SLIDE_REGEX = /slide\/(\d+)(\/\w{2})?(.+)?/;

// Handle changing the language while presenting education lessons
export default class extends Controller {
  handleEvent = (evt) => {
    const langName = evt.detail.item.innerText;
    const langLocale = evt.detail.item.value;
    this.setCookies(langName);
    this.updateLocationForLocale(langLocale);
  };

  setCookies (langName) {
    if (this.presentationPath()) {
      Cookies.set("subtitleSettings", langName);
    }
  }

  updateLocationForLocale (langLocale) {
    if (this.presentationPath()) {
      // This is updating the path on the URL
      // e.g. http://localhost:4000/education/lesson/1/slide/3/en
      window.location.href = window.location.href.replace(SLIDE_REGEX, `slide/$1/${langLocale}$3`);
    } else {
      // This is updating the params on the URL
      // e.g. https://os.teamshares.com/quizzes?locale=es
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set("locale", langLocale);
      window.location.search = searchParams.toString();
    }
  }

  presentationPath () {
    return SLIDE_REGEX.test(window.location.pathname);
  }
}
