import { Controller } from "@hotwired/stimulus";
import Cookies from "js-cookie";

// Note: this relies on window.videojs being loaded
export default class extends Controller {
  static targets = [
    "wrapper",
    "frame",
    "spinner",
    "fullscreenButton",
    "nextLink",
    "prevLink",
    "jumpTrigger",
    "jumpInput",
    "jumpNumber",
  ];

  connect = () => {
    const elem = this.frameTarget;
    elem.addEventListener("turbo:before-fetch-request", this._handleFrameRequest.bind(this));
    elem.addEventListener("turbo:frame-load", this._handleFrameLoad.bind(this));
    window.addEventListener("load", this._handleVideoSlide.bind(this));
    window.addEventListener("resize", this._memoizedResizeHandler().bind(this));

    // The president preview functionality does not include fullscreen abilities
    if (this.hasFullscreenButtonTarget) {
      document.addEventListener("keyup", this._handleKeyPress);
      document.addEventListener("fullscreenchange", this._handleFullScreenChange);
      document.addEventListener("webkitfullscreenchange", this._handleFullScreenChange);
      document.addEventListener("mozfullscreenchange", this._handleFullScreenChange);
      document.addEventListener("MSFullscreenChange", this._handleFullScreenChange);
    }

    this._resetEventListeners();
    this._handleVideoSlide();
    this._handleMarkdownSlide();
  };

  /* These need to be reset after each frame load because the jump nav is inside the turbo frame */
  _resetEventListeners = () => {
    // The EO review functionality does not include jumping ahead
    if (this.hasJumpTriggerTarget) {
      this.jumpTriggerTarget.addEventListener("click", this._handleJumpTrigger);
      this.jumpInputTarget.addEventListener("blur", this._handleJumpInputBlur);
      this.jumpInputTarget.addEventListener("click", this._handleJumpInputClick);
      this.jumpInputTarget.addEventListener("input", this._handleJumpInputOnInput);
      this.jumpInputTarget.addEventListener("keyup", this._handleJumpInputKeyup);
    }
  };

  disconnect = () => {
    const elem = this.frameTarget;
    elem.removeEventListener("turbo:before-fetch-request", this._handleFrameRequest.bind(this));
    elem.removeEventListener("turbo:frame-load", this._handleFrameLoad.bind(this));

    // The president preview functionality does not include fullscreen abilities
    if (this.hasFullscreenButtonTarget) {
      document.removeEventListener("keyup", this._handleKeyPress);
      document.removeEventListener("fullscreenchange", this._handleFullScreenChange);
      document.removeEventListener("webkitfullscreenchange", this._handleFullScreenChange);
      document.removeEventListener("mozfullscreenchange", this._handleFullScreenChange);
      document.removeEventListener("MSFullscreenChange", this._handleFullScreenChange);
    }

    // The EO review functionality does not include jumping ahead
    if (this.hasJumpTriggerTarget) {
      this.jumpTriggerTarget.removeEventListener("click", this._handleJumpTrigger);
      this.jumpInputTarget.removeEventListener("blur", this._handleJumpInputBlur);
      this.jumpInputTarget.removeEventListener("click", this._handleJumpInputClick);
      this.jumpInputTarget.removeEventListener("input", this._handleJumpInputOnInput);
      this.jumpInputTarget.removeEventListener("keyup", this._handleJumpInputKeyup);
    }
  };

  isFullscreen = () => {
    return this.wrapperTarget.classList.contains("fullscreen");
  };

  toggleFullscreen = () => {
    const elem = this.wrapperTarget;
    if (this.isFullscreen()) {
      this._fullscreenOff(elem);
    } else {
      this._fullscreenOn(elem);
    }
  };

  _fullscreenOn = (elem) => {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    } else {
      alert("Sorry, your browser doesn't appear to support fullscreen presentations :/");
      return;
    }
    elem.classList.add("fullscreen");
  };

  _fullscreenOff = (elem) => {
    const exitFunc = document.exitFullscreen || document.webkitExitFullscreen || document.msExitFullscreen;
    if (exitFunc && (document.fullscreenElement !== null)) {
      const exitFullscreen = exitFunc.bind(document);
      exitFullscreen();
    }
    elem.classList.remove("fullscreen");
  };

  _handleKeyPress = (evt) => {
    if (evt.code === "Enter") this.toggleFullscreen();
    if (evt.code === "ArrowRight" && this.hasNextLinkTarget) this.nextLinkTarget.click();
    if (evt.code === "ArrowLeft" && this.hasPrevLinkTarget) this.prevLinkTarget.click();
  };

  _handleVideoSlide = () => {
    const video = document.getElementsByTagName("video")[0];
    if (video && window.videojs) {
      window.videojs(video, {});
      const player = window.videojs.players[document.getElementsByClassName("video-js")[0].id];
      player.on("play", () => this._handleSubtitles(player));
    }
  };

  _handleMarkdownSlide = () => {
    // For long on-screen URLs, insert an invisible word-break character after the "/"
    const markdownLink = document.querySelector(".slide-content--markdown a");
    if (markdownLink) {
      markdownLink.innerText = markdownLink.innerText.replace(".com/", ".com/\u200B");
    }
  };

  _handleSubtitles = (player) => {
    const tracks = player.textTracks();

    tracks.addEventListener("change", (event) => {
      // Note: event.target.activeElement is undefined in safari, this only works if we explicitly set this variable to document.activeElement.innerText in safari
      const subtitleSettings = event.target.activeElement ? event.target.activeElement.innerText : document.activeElement.innerText;
      // This covers the edge case when subtitles are switched automatically between slides
      if (subtitleSettings !== "") {
        Cookies.set("subtitleSettings", subtitleSettings);
      }
    });
    const cookies = Cookies.get("subtitleSettings") || "";
    const wantedTrack = cookies.split(" ");
    tracks.tracks_.forEach(track => {
      if (track.kind === "captions" && wantedTrack.includes(track.label)) {
        // Activate if the language was persisted
        track.mode = "showing";
      }
    });

    this._persistSubtitleFontPercentage();
  };

  // Persist font percentage selection
  _persistSubtitleFontPercentage = () => {
    const trackSetting = document.getElementsByClassName("vjs-font-percent vjs-track-setting")[0];
    trackSetting.addEventListener("change", (event) => {
      Cookies.set("subtitleFontPercentage", event.target.value);
    });

    trackSetting.querySelector("select").value = Cookies.get("subtitleFontPercentage") || "1.00";
    document.getElementsByClassName("vjs-done-button")[0].click();
  };

  _handleFullScreenChange = () => {
    if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      const elem = this.wrapperTarget;
      elem.classList.remove("fullscreen");
    }
  };

  _handleJumpTrigger = () => {
    this.jumpTriggerTarget.classList.toggle("input");
    const input = this.jumpInputTarget;
    const path = window.location.pathname;
    this.currSlideNum = path.slice(path.lastIndexOf("/") + 1);
    input.value = this.currSlideNum;
    input.focus();
    input.select();
  };

  // Limit number of characters that can be entered
  _handleJumpInputOnInput = () => {
    const target = this.jumpInputTarget;
    target.value = target.value.slice(0, target.maxLength);
  };

  _handleJumpInputKeyup = (evt) => {
    const target = this.jumpInputTarget;
    if (evt.key === "Enter") {
      evt.stopPropagation();
      evt.preventDefault();
      target.blur();
    }
    if (evt.key === "Escape") {
      evt.stopPropagation();
      evt.preventDefault();
      this._cancelJumpInput();
    }
  };

  _handleJumpInputBlur = (evt) => {
    this.jumpTriggerTarget.classList.toggle("input");
    const el = evt.srcElement;
    if (el.value === "" || el.value === this.currSlideNum) return;
    // If they try to go beyond the last slide, send them to the last slide
    const val = (parseInt(el.value) > parseInt(el.max)) ? el.max : el.value;
    el.value = "";
    this.jumpNumberTarget.innerHTML = val;
    this.jumpInputTarget.value = val;
    this._jumpToSlide(val);
  };

  _handleJumpInputClick = (evt) => {
    evt.stopPropagation();
    evt.target.focus();
    evt.target.select();
  };

  _cancelJumpInput = () => {
    this.jumpInputTarget.value = "";
    this.jumpInputTarget.blur();
  };

  _jumpToSlide = (slideNum) => {
    // The path may or may not end with a locale (e.g., "/es") or querystring
    const currPath = window.location.href;
    const regex = /\/slide\/(\d+)/;
    const newPath = currPath.replace(regex, `/slide/${slideNum}`);
    this.frameTarget.src = newPath;
    // Update the URL bar to match
    window.history.replaceState({}, "", newPath);
  };

  _handleFrameRequest = () => {
    this.spinnerTarget.classList.add("spinner-wrapper--visible");
    this.nextLinkTarget.disabled = true;
    if (this.hasPrevLinkTarget) this.prevLinkTarget.disabled = true;
  };

  _handleFrameLoad = () => {
    this.spinnerTarget.classList.remove("spinner-wrapper--visible");
    this._resetEventListeners();
    this._handleVideoSlide();
    this._handleMarkdownSlide();
    this._memoizedResizeHandler();
    window.scrollTo(0, 0);
  };

  // Set a root-level "--vh" CSS property to the actual inner height; necessary for mobile browsers which don't account for the browser bar
  _memoizedResizeHandler = () => {
    let prevHeight = 0;
    const handleResize = () => {
      const currHeight = window.innerHeight;
      if (currHeight === prevHeight) return;
      requestAnimationFrame(() => {
        document.documentElement.style.setProperty("--vh", `${currHeight * 0.01}px`);
        prevHeight = currHeight;
      });
    };
    handleResize();
    return handleResize;
  };
}
