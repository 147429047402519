import { Controller } from "@hotwired/stimulus";

let timeout;
let safeHarbor;
let hsa2Compliant;
const successColor = "#EEF6E8";
const dangerColor = "#FCF1EF";
const safeHarborRate = 0.0839;

export default class extends Controller {
  static targets = [
    "safeHarbor",
    "employerContribution",
    "pricing",
    "employerHSA2",
    "employeeHSA2",
    "successAlert",
    "dangerAlert",
    "submit",
  ];

  calculateSafeHarbor (event) {
    clearTimeout(timeout);

    const payRate = event.target.value.replace(/[^\d.]+/g, "") || 0;
    timeout = setTimeout(() => {
      safeHarbor = safeHarborRate * 130 * parseFloat(payRate);
      this.safeHarborTarget.innerHTML = `$${this.formatUSD(safeHarbor)}`;
      this.setHSA2(safeHarbor);
      this.validateRequiredFields();
    }, 500);
  }

  calculateEmployeeContribution (event) {
    clearTimeout(timeout);

    const employerContribution = event.target.value.replace(/[^\d.]+/g, "") || 0;
    timeout = setTimeout(() => {
      this.pricingTargets.forEach(pricing => {
        if (pricing.dataset.cell === event.target.dataset.cell) {
          const employeeContribution = parseFloat(pricing.dataset.pricing) - parseFloat(employerContribution);
          pricing.innerHTML = `$${this.formatUSD(employeeContribution)}`;
          if (event.target.dataset.cell === "ee-hsa2" && safeHarbor) this.renderCompliance(employeeContribution, event.target);
        }
      });
      this.validateRequiredFields();
    }, 500);
  }

  validateRequiredFields () {
    const isValid = this.employerContributionTargets.every((field) => field.value && field.value.length) && hsa2Compliant;

    this.submitTarget.disabled = !isValid;
  }

  renderCompliance (employeeContribution, input) {
    if (employeeContribution <= safeHarbor) {
      hsa2Compliant = true;
      this.setBackground(successColor);
      this.dangerAlertTarget.open = false;
      this.successAlertTarget.open = true;
      input.parentElement.classList.remove("field_with_errors");
    } else {
      hsa2Compliant = false;
      this.setBackground(dangerColor);
      this.successAlertTarget.open = false;
      this.dangerAlertTarget.open = true;
      input.parentElement.classList.add("field_with_errors");
    }
  }

  setHSA2 (safeHarbor) {
    const pricing = parseFloat(this.employeeHSA2Target.dataset.pricing);

    this.employerHSA2Target.value = `${this.formatUSD(pricing - safeHarbor)}`;
    this.employeeHSA2Target.innerHTML = `$${this.formatUSD(safeHarbor)}`;

    this.renderCompliance(safeHarbor);
  }

  setBackground (color) {
    this.employeeHSA2Target.parentNode.style.backgroundColor = `${color}`;
  }

  formatUSD (value) {
    return new Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(value);
  }
}
