import { Controller } from "@hotwired/stimulus";
const pattern = /NEW_RECORD/g;
const regex = new RegExp(pattern);

export default class extends Controller {
  static values = {
    existingQuizAssociations: String,
  };

  static targets = [
    "lessons",
    "quizName",
    "quizType",
    "lessonWarning",
    "newQuestionButton",
    "newQuestion",
    "questionFormRow",
    "questionPositionIcon",
    "questionsContainer",
    "questionPositionId",
  ];

  connect () {
    this.initialQuizName = this.quizNameTarget.value;
    this.quizTypeTarget.addEventListener("change", this.getSelectedText.bind(this));
    this.lessonsTarget.addEventListener("sl-change", this.getSelectedText.bind(this));

    this.newQuestionButtonTarget.addEventListener("click", () => this.addRow());

    // Rails expects has_many associations in a dynamic, nested form to have
    // incrementing child indices. Normally this index would increment by 1 for each
    // additional record, but as long as the index is increasing, Rails knows how to save
    // these child objects and associate them with their parent.
    // Here, we start at 999 to give us a stable initial value for testing
    // (works as long as we don't have a thousand items in any existing form)

    this.curIndex = 999;
  }

  getSelectedText () {
    const quizType = this.quizTypeTarget.value;
    const lessons = this.lessonsTarget.value;
    this.lessonWarningTarget.innerHTML = "";
    const hash = JSON.parse(this.existingQuizAssociationsValue);

    for (const lesson of lessons) {
      if (quizType !== "poll") {
        this.lessonWarningTarget.innerHTML += this.warningMessage(hash[lesson].title, hash[lesson][quizType]);
      }
    }
  }

  warningMessage (lessonTitle, oldQuiz) {
    if (!oldQuiz) {
      return `${lessonTitle} has no ${this.quizTypeTarget.value} quiz associated with it. This quiz (${this.quizNameTarget.value}) will become the active ${this.quizTypeTarget.value} for ${lessonTitle}.<br><br>`;
    } else if (oldQuiz === this.initialQuizName) {
      return "";
    }

    return `${lessonTitle} already has a ${this.quizTypeTarget.value} quiz associated (${oldQuiz}). This quiz (${this.quizNameTarget.value}) will become the active ${this.quizTypeTarget.value} for ${lessonTitle}.<br><br>`;
  }

  addRow () {
    // replace the new question index w a new one
    const content = this.newQuestionTarget.innerHTML.replace(regex, ++this.curIndex);

    // grab the last position id and add 1 to it, or start from scratch
    const lastPosition = parseInt(this.questionPositionIdTargets?.pop()?.value || 0);
    const nextPosition = this.questionPositionIdTargets.length === 0 ? 0 : lastPosition + 1;

    // stick it on the end of the existing questions
    this.questionsContainerTarget.insertAdjacentHTML("beforeend", content);

    // grab the new question
    const newQuestionRow = this.questionFormRowTargets.pop();

    // grab the new row and put the next position in it
    const newQuestionPositionId = this._targetQuestionPositionId(newQuestionRow);
    newQuestionPositionId.value = nextPosition;
  }

  _targetQuestionPositionId = (target) => {
    return this.questionPositionIdTargets.find((questionPositionId) => target.contains(questionPositionId));
  };
}
